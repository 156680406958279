<template>
  <vca-card>
    <h2>{{ $t("faqs.festivals.title") }}</h2>
    <p v-html="$t('faqs.festivals.description')" />
    <br />
    <Festivals />
  </vca-card>
</template>
<script>
import Festivals from "@/components/faqs/FaqsFestivals";
export default {
  name: "FaqsFestivals",
  components: { Festivals },
};
</script>
